import React, { useMemo } from 'react'
import { format } from 'date-fns'
import { Box, Tag, Text, Grid, GridItem, Table, Thead, Tr, Th, Tbody, Td, Flex } from '@chakra-ui/react'
import { RouteComponentProps } from '@reach/router'
import OrderStatusTag from '../order-status-tag'
import Seo from '~components/shared/seo'
import Heading from '~components/shared/heading'
import ContentBox from '~components/shared/content-box'
import ContentBoxTitle from '~components/shared/content-box-title'
import { useTicketOrderQuery } from '~graphql/generated'
import DetailSection from '~components/shared/content-description'

interface Props extends RouteComponentProps {
  id?: string
}

const OrderDetailsPage: React.FC<Props> = ({ id }) => {
  const { data } = useTicketOrderQuery({
    skip: !id,
    variables: { id: id || '' },
  })

  const order = useMemo(() => data?.ticketorder, [data])

  const eventDetails = [
    { label: 'Event name:', value: data?.ticketorder?.event_name },
    { label: 'Event id:', value: data?.ticketorder?.ticket_sku?.festival?.id },
    { label: 'Slug name:', value: data?.ticketorder?.ticket_sku?.festival?.slug_name },
    { label: 'Event Date:', value: data?.ticketorder?.event_date }
  ]

  const orderDetails = [
    { label: 'Number of Tickets:', value: data?.ticketorder?.tickets_qty },
    { label: 'Wholesale cost:', value: data?.ticketorder?.ticket_sku?.wholesale_cost },
    { label: 'Credit cost:', value: data?.ticketorder?.ticket_sku?.credit_cost },
    { label: 'Credit total cost:', value: data?.ticketorder?.tickets_qty * data?.ticketorder?.ticket_sku?.credit_cost },
    { label: 'Final invoice amount:', value: data?.ticketorder?.final_invoice_amount },
    { label: 'Delivery Method:', value: data?.ticketorder?.delivery_method },
    { label: 'Created at Date:', value: data?.ticketorder?.created_at },
    { label: 'Update at Date:', value: data?.ticketorder?.updated_at }
  ]

  const buyerInfo = [
    { label: 'id:', value: data?.ticketorder?.attendees[0]?.id },
    { label: 'Name:', value: data?.ticketorder?.attendees[0]?.first_name },
    { label: 'Last name:', value: data?.ticketorder?.attendees[0]?.last_name }
  ]

  const orderStatus = [
    { label: 'Fulfillment confirmation id:', value: data?.ticketorder?.fulfillment_confirmation_id },
    { label: 'Fulfillment status:', value: data?.ticketorder?.fulfillment_status }
  ]

  return (
    <>
      <Seo title={`Order #${id}`} />
      <Flex alignItems="center" gap={4}>
        <Heading>Order #{id}</Heading>
        <OrderStatusTag
          size="md"
          status={data?.ticketorder?.fulfillment_status || undefined}
          mb={6}
        />
      </Flex>
      {/* <Grid templateColumns={['repeat(3, 1fr)']} gap={[4, 6]}>
        <GridItem colSpan={2}>
          <ContentBox>
            <ContentBoxTitle>Tickets ({order?.tickets_qty})</ContentBoxTitle>
            {order?.ticketstubs?.map(stub => (
              <Box key={stub?.ticket_id}>
                <Tag>{stub?.ticket_id}</Tag>
              </Box>
            ))}
          </ContentBox>
        </GridItem>
        <GridItem>
          <ContentBox>
            <ContentBoxTitle>Details</ContentBoxTitle>
            <Text>Credits cost: {(order?.ticket_sku.credit_cost * order?.tickets_qty) ?? "--"}</Text>
            <Text>
              Order date:{' '}
              {format(new Date(order?.created_at || null), 'MMM do yy hh:mm')}
            </Text>
          </ContentBox>
        </GridItem>
        <GridItem>
          <ContentBox>
            <ContentBoxTitle>Delivery Status</ContentBoxTitle>
          </ContentBox>
        </GridItem>
        <GridItem>
          <ContentBox>
            <ContentBoxTitle>Delivery Method</ContentBoxTitle>
          </ContentBox>
        </GridItem>
      </Grid> */}
      <DetailSection title="Event Details" data={eventDetails} />
      <DetailSection title="Order Details" data={orderDetails} />
      {data?.ticketorder?.ticketstubs && data?.ticketorder?.ticketstubs.length > 0 &&
      <Box backgroundColor="#F5F6F8" w="100%" h="auto" borderRadius="8px" padding="16px 27px" my={4}>
        <Text color="#2D2D2D" fontWeight="700" fontSize="md" mb={3}>Ticket Information:</Text>
        <Table variant="unstyled" size="sm">
          <Thead borderBottom="1px solid #B2B2B2">
            <Tr>
              <Th>id</Th>
              <Th>Ticket id</Th>
              <Th>Type</Th>
              <Th>Row</Th>
              <Th>Section</Th>
              <Th>Seat</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.ticketorder?.ticketstubs.map((u, i) => (
              <Tr key={u?.id}>
                <Td>
                  {u?.id}
                </Td>
                <Td>
                  {u?.ticket_id}
                </Td>
                <Td>
                  Ticket
                </Td>
                <Td>
                  <Text>{u?.row ?? "--"}</Text>
                </Td>
                <Td>
                  {u?.section ?? "--"}
                </Td>
                <Td>
                  {u?.seat ?? "--"}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>}
      <DetailSection title="Buyer Information" data={buyerInfo} />
      <DetailSection title="Order Status" data={orderStatus} />
      {/* <pre>{JSON.stringify(data, undefined, 2)}</pre> */}
    </>
  )
}

export default OrderDetailsPage
