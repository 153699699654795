import React from 'react'
import { Router } from '@reach/router'

import OrdersPage from '~components/pages/orders/orders-page'
import OrderDetailsPage from '~components/pages/orders/order-details-page'

const OrdersPageRouter = () => {
  return (
    <Router basepath="/orders">
      <OrdersPage path="/" />
      <OrderDetailsPage path="/:id" />
    </Router>
  )
}

export default OrdersPageRouter
