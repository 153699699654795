import React from 'react'
import { Box, Text, Grid, GridItem } from '@chakra-ui/react'

interface DetailItem {
  label: string
  value: string | number | null | undefined
}

interface DetailSectionProps {
  title: string
  data: DetailItem[]
}

const DetailSection: React.FC<DetailSectionProps> = ({ title, data }) => {
  return (
    <Box backgroundColor="#F5F6F8" w="100%" h="auto" borderRadius="8px" padding="16px 27px" my={4}>
      <Text color="#2D2D2D" fontWeight="700" fontSize="md" mb={4}>{title}</Text>
      <Grid templateColumns={['repeat(2, 1fr)']} w="80%" rowGap={"4"}>
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <GridItem>
              <Text color="#2D2D2D" fontSize="md">{item.label}</Text>
            </GridItem>
            <GridItem>
              <Text color="#2D2D2D" fontWeight="700" fontSize="md" textTransform="capitalize">{item.value ?? '--'}</Text>
            </GridItem>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  )
}

export default DetailSection
