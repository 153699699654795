import React, { useState, useEffect, useMemo } from 'react'
import querystring from 'query-string'
import { navigate } from 'gatsby'
import { useLocation, RouteComponentProps } from '@reach/router'
import {
  Text,
  Image,
  Center,
  Progress,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
} from '@chakra-ui/react'

import Seo from '~components/shared/seo'
import Heading from '~components/shared/heading'
import ContentBox from '~components/shared/content-box'
import OrdersTable from '~components/pages/orders/table'
import OrderDetailsDrawer from '~components/pages/orders/order-details-drawer'
import ServerDown from '~images/illustrations/server-down.svg'
import {
  useTicketOrdersQuery,
  TicketOrderFieldsFragment,
  Enum_Ticketorders_Fulfillment_Status,
} from '~graphql/generated'
import { useScroll } from '~context/scroll-provider'

const OrdersPage: React.FC<RouteComponentProps> = () => {
  const toast = useToast()
  const { search } = useLocation()
  const { isBottom } = useScroll()
  const { status, order } = querystring.parse(search)

  const [limit, setLimit] = useState(20)
  const [orders, setOrders] = useState<TicketOrderFieldsFragment[]>([])

  const tabIndex = useMemo(() => {
    if (status === 'confirmed') return 1
    if (status === 'delivery initiated') return 2
    if (status === 'delivery accepted') return 3
    if (status === 'fulfilled') return 4
    if (status === 'canceled') return 5
    return 0
  }, [status])

  const tabStatus = useMemo(() => {
    if (status === 'confirmed')
      return Enum_Ticketorders_Fulfillment_Status.Confirmed
    if (status === 'delivery initiated')
      return Enum_Ticketorders_Fulfillment_Status.DeliveryInitiated
    if (status === 'delivery accepted')
      return Enum_Ticketorders_Fulfillment_Status.DeliveryAccepted
    if (status === 'fulfilled')
      return Enum_Ticketorders_Fulfillment_Status.Fulfilled
    if (status === 'canceled')
      return Enum_Ticketorders_Fulfillment_Status.Canceled
    return Enum_Ticketorders_Fulfillment_Status.Pending
  }, [status])

  const selectedOrder = useMemo(() => {
    return orders.find(o => o.id === order)
  }, [order, orders])

  const { loading, error, data, refetch } = useTicketOrdersQuery({
    variables: { limit, status: tabStatus },
    onCompleted: data =>
      setOrders(data.ticketorders as TicketOrderFieldsFragment[]),
    onError: error =>
      toast({
        status: 'error',
        title: error.name,
        description: `Failed to fetch ${tabStatus} orders`,
      }),
  })

  useEffect(() => {
    if (isBottom && limit <= orders.length) setLimit(limit + 20)
  }, [isBottom])

  useEffect(() => {
    setLimit(20)
    setOrders([])
  }, [status])

  useEffect(() => {
    refetch({ limit, status: tabStatus });
    if (data) {
      setOrders(data.ticketorders as TicketOrderFieldsFragment[])
    }
  }, [data, status])

  const handleTabClick = (tab: string) => {
    navigate(`?status=${tab.toLowerCase()}`)
  }

  const handleOrderClick = (orderId: string) => {
    navigate(
      `?${querystring.stringify({
        ...querystring.parse(search),
        order: orderId,
      })}`
    )
  }

  const handleCloseDrawer = () => {
    navigate(
      `?${querystring.stringify({
        ...querystring.parse(search),
        order: undefined,
      })}`
    )
  }

  return (
    <>
      <Seo title="Orders" />
      <Heading>Orders</Heading>
      <Tabs index={tabIndex} isLazy>
        <TabList mb={4}>
          {[
            'Pending',
            'Confirmed',
            'Delivery Initiated',
            'Delivery Accepted',
            'Fulfilled',
            'Canceled',
          ].map(i => (
            <Tab
              key={i}
              borderBottomWidth="4px"
              _selected={{
                fontWeight: 'bold',
                borderColor: 'brand.300',
              }}
              onClick={() => handleTabClick(i)}
            >
              {i}
            </Tab>
          ))}
        </TabList>

        <ContentBox p={0} py={4}>
          <TabPanels>
            {[
              Enum_Ticketorders_Fulfillment_Status.Pending,
              Enum_Ticketorders_Fulfillment_Status.Confirmed,
              Enum_Ticketorders_Fulfillment_Status.DeliveryInitiated,
              Enum_Ticketorders_Fulfillment_Status.DeliveryAccepted,
              Enum_Ticketorders_Fulfillment_Status.Fulfilled,
              Enum_Ticketorders_Fulfillment_Status.Canceled,
            ].map(s => (
              <TabPanel p={0} key={s}>
                <Text ml={6} my={2} fontSize="sm" fontWeight="semibold">
                  {loading
                    ? 'Loading...'
                    : `${orders.length} ${tabStatus} orders`}
                </Text>
                <OrdersTable
                  orders={orders}
                  handleOrderClick={handleOrderClick}
                />
                <Progress
                  h="2px"
                  isIndeterminate={loading}
                  colorScheme="brand"
                />
                {orders.length > 0 && (
                  <Text
                    ml={6}
                    mt={4}
                    mb={2}
                    fontSize="sm"
                    fontWeight="semibold"
                  >
                    {`${orders.length} ${tabStatus} orders`}
                  </Text>
                )}
                {error && (
                  <Center py={16} flexDir="column">
                    <Image src={ServerDown} w="240px" />
                    <Text mt={4} fontSize="sm">
                      {error.name}: {error.message}
                    </Text>
                  </Center>
                )}
              </TabPanel>
            ))}
          </TabPanels>
        </ContentBox>
      </Tabs>

      <OrderDetailsDrawer
        isOpen={!!order && !!selectedOrder}
        onClose={handleCloseDrawer}
        order={selectedOrder}
      />
    </>
  )
}

export default OrdersPage
